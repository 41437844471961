import axios from 'axios';
import { FEED_URL } from './const';
import {
  forex_symbols,
  indices_symbols,
  commodity_symbols,
  us_stocks,
  hk_stocks,
  uk_stocks,
  eu_stocks,
  crypto_symbols,
  futures_symbols,
} from './symbols.js';

const currency_country = {
  AUD: ['australia'],
  CAD: ['canada'],
  CHF: ['switzerland'],
  CZK: ['czech'],
  EUR: ['germany', 'france'],
  GBP: ['united-kingdom'],
  HKD: ['hong-kong'],
  HUF: ['hungary'],
  ILS: ['israel'],
  JPY: ['japan'],
  MXN: ['mexico'],
  NOK: ['norway'],
  NZD: ['new-zealand'],
  PLN: ['poland'],
  RUB: ['russia'],
  SEK: ['sweden'],
  SGD: ['singapore'],
  THB: ['thailand'],
  TRY: ['turkey'],
  USD: ['united-states'],
  ZAR: ['south-africa'],
};

const currency_sign = {
  USD: '$',
  EUR: '€',
  JPY: '¥',
  AUD: '$',
  CAD: '$',
  GBP: '£',
};

const country_flag = {
  australia: 'https://s3-symbol-logo.tradingview.com/country/AU.svg',
  canada: 'https://s3-symbol-logo.tradingview.com/country/CA.svg',
  switzerland: 'https://s3-symbol-logo.tradingview.com/country/CH.svg',
  germany: 'https://s3-symbol-logo.tradingview.com/country/EU.svg',
  'united-kingdom': 'https://s3-symbol-logo.tradingview.com/country/UK.svg',
  france: 'https://s3-symbol-logo.tradingview.com/country/EU.svg',
  'hong-kong': 'https://s3-symbol-logo.tradingview.com/country/HK.svg',
  hungary: 'https://s3-symbol-logo.tradingview.com/country/HU.svg',
  israel: 'https://s3-symbol-logo.tradingview.com/country/IL.svg',
  japan: 'https://s3-symbol-logo.tradingview.com/country/JP.svg',
  norway: 'https://s3-symbol-logo.tradingview.com/country/NO.svg',
  'new-zealand': 'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
  poland: 'https://s3-symbol-logo.tradingview.com/country/PL.svg',
  russia: 'https://s3-symbol-logo.tradingview.com/country/RU.svg',
  sweden: 'https://s3-symbol-logo.tradingview.com/country/SE.svg',
  singapore: 'https://s3-symbol-logo.tradingview.com/country/SG.svg',
  thailand: 'https://s3-symbol-logo.tradingview.com/country/TH.svg',
  'united-states': 'https://s3-symbol-logo.tradingview.com/country/US.svg',
  'south-africa': 'https://s3-symbol-logo.tradingview.com/country/ZA.svg',
};

const isLoggingEnabled = true;
export function logMessage(message, ...args) {
  if (isLoggingEnabled) {
    const now = new Date();
    if (args.length > 0) {
      console.log(
        `${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`,
        args,
      );
    } else {
      console.log(
        `${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`,
      );
    }
  }
}

export function logErrorMessage(message, ...args) {
  const now = new Date();
  if (args.length > 0) {
    console.error(
      `${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`,
      args,
    );
  } else {
    console.error(
      `${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`,
    );
  }
}

export function getErrorMessage(error) {
  if (error === undefined) {
    return '';
  } else if (typeof error === 'string') {
    return error;
  }
  return error.message;
}

// Makes requests to TraderMade API
export function makeApiRequest(path, data = {}) {
  return new Promise((resolve, error) => {
    try {
      let config = {
        url: `${FEED_URL}/${path}`,
        method: 'POST',
        data: data,
      };
      axios
        .request(config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          error(e.message);
        });
    } catch (error) {
      error(`TraderMade request error: ${error.status}`);
    }
  });
}

export function getDependencyCountries(currency) {
  let countries = [];
  if (currency_country[currency]) {
    countries = [...currency_country[currency]];
  }
  return countries;
}

export function getFlagFromCountry(country) {
  return country_flag[country];
}

export const allSymbols = {
  ...forex_symbols,
  ...indices_symbols,
  ...commodity_symbols,
  ...us_stocks,
  ...hk_stocks,
  ...uk_stocks,
  ...eu_stocks,
  ...crypto_symbols,
  ...futures_symbols,
};

// Symbol getter function
export function getSymbol(symbol) {
  return allSymbols[symbol] ? allSymbols[symbol] : null;
}

// Symbol type getter function
export function getSymbolType(symbol) {
  const sym = getSymbol(symbol);
  let type = sym ? sym.type : null;
  return convertType(type);
}

export function convertType(type) {
  if (type != null) {
    switch (type) {
      case 'us stocks':
      case 'uk stocks':
      case 'hk stocks':
      case 'eu stocks':
        type = 'stock';
        break;
    }
  }
  return type;
}

export function formatDateTimeWithTimezone(dateTime, timezone) {
  return moment(dateTime).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function isValidSymbol(symbol) {
  const symbolInfo = getSymbol(symbol);
  return !!symbolInfo;
}

export function hasTwoOrFewerDecimalPlaces(qty) {
  if ((qty.toString().split('.')[1] || '').length <= 2) {
    return { isValidQty: true, message: 'Valid volume.' };
  } else {
    return { isValidQty: false, message: 'Invalid volume.' };
  }
}

export function getCurrentCandleTimeFromResolution(resolution) {
  let currentCandleTime = 0;
  switch (resolution) {
    case '1':
    case '3':
    case '5':
    case '10':
    case '15':
    case '30':
      currentCandleTime = convertCurrentTime(parseInt(resolution), 'minute');
      break;
    case '1D':
      currentCandleTime = moment().utc().startOf('days').valueOf();
      break;
    case '1W':
      currentCandleTime = moment().utc().startOf('week').valueOf();
      break;
    case '1M':
      currentCandleTime = moment().utc().startOf('month').valueOf();
      break;
    default:
      currentCandleTime = moment().valueOf();
      break;
  }
  return currentCandleTime;
}

function convertCurrentTime(devider, group) {
  let remainder = 0;
  if (group === 'minute') {
    remainder = moment().utc().minute() % devider;
  } else if (group === 'hour') {
    remainder = moment().utc().hour() % devider;
  }
  const currentCandleTime = moment()
    .utc()
    .subtract(remainder, group)
    .startOf(group)
    .valueOf();
  return currentCandleTime;
}

export function multiply(a, b) {
  const commonMultiplier = 1000000;
  a *= commonMultiplier;
  b *= commonMultiplier;
  return String((a * b) / (commonMultiplier * commonMultiplier));
}

export function getcurrencySign(currency) {
  return currency_sign[currency] || '$';
}

export function calculateChAndChp(currentPrice, openPrice) {
  const ch = currentPrice - openPrice;
  const chp = openPrice ? (ch / openPrice) * 100 : undefined;
  return { ch, chp };
}
