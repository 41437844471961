import {
  endsWith,
  filter,
  find,
  findIndex,
  isEmpty,
  remove,
  toLower,
  values,
  without,
} from 'lodash';
import {
  makeApiRequest,
  getDependencyCountries,
  getFlagFromCountry,
  getSymbolType,
  convertType,
  getSymbol,
  logMessage,
  getErrorMessage,
  logErrorMessage,
  allSymbols,
  isValidSymbol,
  calculateChAndChp,
  getCurrentCandleTimeFromResolution,
} from '../../src/helpers.js';
import { FEED_SOCK_URL } from '../../src/const.js';
import { io } from 'socket.io-client';

const _barSubscriptions = {};
const _quoteSubscriptions = {};
const _depthSubscriptions = {};
const _barSymbolSubscribers = {};
const _quoteSymbolSubscribers = {};
const _quoteSubscriptionsInfo = {};
const _priceType = 'bid';
const socket = io(FEED_SOCK_URL, {
  withCredentials: true,
});

socket.on('disconnect', () => {
  logErrorMessage('socket: Disconnected');
  window.showSessionDisconnectedModal();
});

// Socket connection function
socket.on('real-time-candle-data', (event) => handleRealTimeCandleData(event));
socket.on('real-time-quote-data', (event) => handleRealTimeQuoteData(event));

// DatafeedConfiguration implementation
const configurationData = {
  supports_search: false,
  supports_group_request: true,
  supports_marks: false,
  supports_timescale_marks: true,
  supports_time: true,
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: [
    '1',
    '3',
    '5',
    '10',
    '15',
    '30',
    '45',
    '60',
    '120',
    '180',
    '240',
    '1D',
    '1W',
    '1M',
    '3M',
    '6M',
    '12M',
  ],
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: '', name: 'C', desc: '$' },
    { value: 'OTC', name: 'OTC', desc: 'Over the counter' },
  ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [
    { name: 'All', value: '' },
    { name: 'Forex', value: 'forex' },
    { name: 'Indices', value: 'index' },
    { name: 'Commodity', value: 'commodity' },
    { name: 'US Stocks', value: 'us stocks' },
    { name: 'HK Stocks', value: 'hk stocks' },
    { name: 'UK Stocks', value: 'uk stocks' },
    { name: 'EU Stocks', value: 'eu stocks' },
    { name: 'Crypto', value: 'crypto' },
    { name: 'Futures', value: 'futures' },
  ],
};

// Socket handle function to process real time candle data
function handleRealTimeCandleData(event) {
  const { symbol, ask, bid, time } = event;
  if (_barSymbolSubscribers[symbol]) {
    const barSymbolSubscribers = _barSymbolSubscribers[symbol];
    barSymbolSubscribers.forEach(
      ({ lastBarTime, listeners, resolution, lastCandle }, index) => {
        if (
          (lastBarTime != null && lastBarTime > time) ||
          ask == 'NaN' ||
          bid == 'NaN'
        ) {
          return;
        }
        const bar = {};
        const currentCandleTime =
          getCurrentCandleTimeFromResolution(resolution);
        if (lastBarTime === currentCandleTime) {
          bar.time = lastBarTime;
          bar.open = lastCandle.open;
          bar.high = Math.max(lastCandle.high, event[_priceType]);
          bar.low = Math.min(lastCandle.low, event[_priceType]);
          bar.close = event[_priceType];
        } else {
          bar.time = time < currentCandleTime ? time : currentCandleTime;
          bar.open = event[_priceType];
          bar.high = event[_priceType];
          bar.low = event[_priceType];
          bar.close = event[_priceType];
        }
        _barSymbolSubscribers[symbol][index].lastBarTime = bar.time;
        _barSymbolSubscribers[symbol][index].lastCandle = bar;
        // Send data to every subscriber of that symbol
        listeners.forEach((listener) => {
          listener.listener(bar);
        });
      },
    );
  }
}

// Socket handle function to process real time quote data
function handleRealTimeQuoteData(event) {
  const { symbol, ask, bid, time } = event;
  if (_quoteSymbolSubscribers[symbol]) {
    const { symbolInfo, listeners } = _quoteSymbolSubscribers[symbol];
    const quoteSubscriptionsInfo = _quoteSubscriptionsInfo[symbol];
    if (quoteSubscriptionsInfo) {
      const { ch, chp } = calculateChAndChp(
        event[_priceType],
        quoteSubscriptionsInfo.v.open_price,
      );
      const quote = {
        ...quoteSubscriptionsInfo,
        v: {
          ...quoteSubscriptionsInfo.v,
          ask: ask,
          bid: bid,
          spread: ask - bid,
          ch,
          chp,
          lp: event[_priceType],
          high_price: Math.max(
            quoteSubscriptionsInfo.v.high_price,
            event[_priceType],
          ),
          low_price: Math.min(
            quoteSubscriptionsInfo.v.low_price,
            event[_priceType],
          ),
          last_price: event[_priceType],
        },
      };
      listeners.forEach((listener) => {
        listener.listener([quote]);
      });
      depthUpdate(
        `DEPTH_UID_${symbolInfo.symbol}`, // UID
        [{ price: ask }], // asks
        [{ price: bid }], // bids
        true, // isSnapshot → for level one data you would probably want this to be true.
      );
      checkQuoteAndUpdate(event);
    }
  }
}

function checkQuoteAndUpdate(event) {
  const { symbol, ask, bid, time } = event;
  const quoteSubscriptionsInfo = _quoteSubscriptionsInfo[symbol];
  if (
    !moment()
      .utc()
      .startOf('day')
      .isSame(moment(quoteSubscriptionsInfo.v.time).utc().startOf('day'), 'day')
  ) {
    const { ch, chp } = calculateChAndChp(
      event[_priceType],
      quoteSubscriptionsInfo.v.open_price,
    );
    const quote = {
      ...quoteSubscriptionsInfo,
      v: {
        ...quoteSubscriptionsInfo.v,
        ask: ask,
        bid: bid,
        spread: ask - bid,
        ch,
        chp,
        lp: event[_priceType],
        high_price: event[_priceType],
        low_price: event[_priceType],
        last_price: event[_priceType],
        open_price: event[_priceType],
        prev_close_price: quoteSubscriptionsInfo.v.last_price,
        time: moment().utc().startOf('day').valueOf(),
      },
    };
    _quoteSubscriptionsInfo[symbol] = quote;
  }
}

function depthUpdate(uid, asks, bids, isSnapshot) {
  const callback = _depthSubscriptions[uid];
  if (!callback || typeof callback !== 'function') return;
  callback({
    asks: asks,
    bids: bids,
    snapshot: isSnapshot,
  });
}

function calculatePriceScale(decimals) {
  if (decimals == undefined || decimals == null || decimals < 0) {
    decimals = 2;
  }
  return Math.pow(10, decimals);
}

// Symbol string getter function for historical candles
function getSymbolStringForHistorical(symbol, resolution) {
  let duration = resolution;
  // days, weeks, months
  if (
    endsWith(resolution, 'D') ||
    endsWith(resolution, 'W') ||
    endsWith(resolution, 'M')
  ) {
    if (endsWith(resolution, 'M')) {
      resolution = resolution.replace('M', 'mo');
    }
    duration = toLower(resolution);
  }
  // minutes/hours
  else {
    resolution = parseInt(resolution) || 1;
    duration = `${resolution}m`;
  }
  return `${symbol}{=${duration},price=${_priceType}}`;
}

function getFromTime(resolution) {
  let duration = parseInt(resolution);
  let unit = 'minutes';
  const utcMoment = moment(new Date()).utc().set('millisecond', 0);
  // days
  if (endsWith(resolution, 'D')) {
    utcMoment.seconds(0).minutes(0).hours(0);
    unit = 'days';
  } // weeks
  else if (endsWith(resolution, 'W')) {
    utcMoment.seconds(0).minutes(0).hours(0);
    unit = 'weeks';
  } // months
  else if (endsWith(resolution, 'M')) {
    utcMoment.seconds(0).minutes(0).hours(0).date(0);
    unit = 'month';
  } else {
    utcMoment.seconds(0);
    unit = 'minutes';
  }
  utcMoment.subtract(duration, unit);
  return utcMoment.valueOf();
}

export class LuxDataFeed {
  onReady(callback) {
    logMessage('DataFeed Ready!!!');
    setTimeout(() => {
      const iframe = document.getElementsByTagName('iframe')[0];
      const toggleBtn =
        iframe.contentWindow.document.querySelector('.button-dA6R3Y1X');
      if (toggleBtn) toggleBtn.click();
      callback(configurationData);
    }, 1000);
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    logMessage(
      `searchSymbol: Searching symbols #${userInput} - {${symbolType}}`,
    );
    const newSymbols = values(allSymbols).filter((symbol) => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      const isSymbolDescriptionContainsInput =
        symbol.description.toLowerCase().indexOf(userInput.toLowerCase()) !==
        -1;
      let typeRes = true;
      if (symbolType) {
        typeRes = symbolType === symbol.type;
      }
      return (
        typeRes &&
        isExchangeValid &&
        (isFullSymbolContainsInput || isSymbolDescriptionContainsInput)
      );
    });
    onResultReadyCallback(newSymbols.slice(0, 50));
  }

  async resolveSymbol(
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
  ) {
    setTimeout(() => {
      logMessage(`resolveSymbol: Resolving symbol #${symbolName}`);
      const symbol = getSymbol(symbolName);
      if (!symbol) {
        logMessage(`resolveSymbol: Can not resolving symbol #${symbolName}`);
        onResolveErrorCallback('Cannot resolve symbol');
        return;
      }
      // Symbol information object
      const symbolInfo = {
        ticker: symbol.full_name,
        name: symbol.symbol,
        description: symbol.description,
        type: convertType(symbol.type),
        session: symbol.session,
        timezone: 'Etc/UTC',
        exchange: symbol.exchange,
        minmov: 1,
        pricescale: calculatePriceScale(symbol.decimals),
        visible_plots_set: 'ohlc',
        has_ticks: false, // Supports resolution in ticks
        has_seconds: false, // Supports resolution in seconds
        has_intraday: true, // Supports resolution in minutes
        intraday_multipliers: ['1', '3', '5', '10', '15', '30', '1D'],
        has_daily: true, // Supports resolution in days
        has_weekly_and_monthly: true, // Supports resolution in weeks/months
        data_status: 'streaming',
        logo_urls: symbol.logo_urls,
        typespecs: this._getSymbolTypeSpecs(convertType(symbol.type)),
      };
      logMessage(`resolveSymbol: Resolved symbol #${symbolName}`, symbolInfo);
      onSymbolResolvedCallback(symbolInfo);
    }, 0);
  }

  _getSymbolTypeSpecs(symbolType) {
    let typespecs = [];
    if (symbolType == 'crypto') {
      typespecs = ['crypto'];
    } else if (symbolType == 'stock') {
      typespecs = ['common'];
    } else if (symbolType != 'forex') {
      typespecs = ['cfd'];
    }
    return typespecs;
  }

  async getBars(
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback,
  ) {
    let { from, to, firstDataRequest } = periodParams;
    let { name: symbol } = symbolInfo;
    const symbolPattern = getSymbolStringForHistorical(symbol, resolution);
    let noData = false;
    if (to === moment().utc().startOf('days').unix()) {
      to = moment().utc().add(1, 'days').startOf('days').unix();
    }
    try {
      const candles = await makeApiRequest('historical', {
        symbol: symbolPattern,
        from: from,
        to: to,
      });
      logMessage('getBars: Historical candles #', candles);
      let bars = [];
      candles.forEach((bar) => {
        if (bar.time >= from * 1000 && bar.time <= to * 1000) {
          let { open, high, low, close, time } = bar;
          bars = [
            ...bars,
            {
              time,
              low,
              high,
              open,
              close,
            },
          ];
        }
      });
      if (
        (candles.length === 0 && endsWith(resolution, 'W')) ||
        endsWith(resolution, 'M')
      ) {
        noData = true;
      }
      logMessage(`getBars: Returned bars count ${bars.length}`);
      if (firstDataRequest) {
        const lastBar = bars[bars.length - 1];
        logMessage('getBars: Last bar #', lastBar);
        if (!_barSymbolSubscribers[symbolInfo.name]) {
          _barSymbolSubscribers[symbolInfo.name] = [];
        }
        _barSymbolSubscribers[symbolInfo.name].push({
          lastBarTime: lastBar?.time,
          lastCandle: lastBar,
          resolution,
          symbolInfo,
          listeners: [],
        });
      }
      onHistoryCallback(bars, { noData: noData });
    } catch (e) {
      logErrorMessage(`getBars: ${getErrorMessage(e)}`, e);
      onErrorCallback({ error: getErrorMessage(e) });
    }
  }

  subscribeBars(symbolInfo, resolution, newDataCallback, listenerGuid) {
    const symbolPattern = getSymbolStringForHistorical(
      symbolInfo.name,
      resolution,
    );
    if (
      _barSymbolSubscribers[symbolInfo.name] &&
      find(_barSymbolSubscribers[symbolInfo.name], {
        resolution,
      })
    ) {
      const index = findIndex(_barSymbolSubscribers[symbolInfo.name], {
        resolution,
      });
      if (
        find(_barSymbolSubscribers[symbolInfo.name][index].listeners, {
          listenerId: listenerGuid,
        })
      ) {
        logMessage(
          `subscribeBar: Already has subscriber with id=${listenerGuid}`,
        );
        return;
      }
      if (isEmpty(_barSymbolSubscribers[symbolInfo.name][0].listeners)) {
        socket.emit('subscribe-bar', symbolInfo.name);
      }
      _barSymbolSubscribers[symbolInfo.name][index].listeners.push({
        listenerId: listenerGuid,
        listener: newDataCallback,
      });
      _barSubscriptions[listenerGuid] = symbolInfo.name;
    }

    logMessage(
      `subscribeBar: Subscribed for #${listenerGuid} - {${symbolPattern}, ${resolution}}`,
    );
  }

  unsubscribeBars(listenerGuid) {
    const symbolPattern = _barSubscriptions[listenerGuid];
    _barSymbolSubscribers[symbolPattern]?.forEach((item) => {
      item.listeners = filter(
        item.listeners,
        (listener) => listener.listenerId !== listenerGuid,
      );
      if (item.listeners.length === 0) {
        // Remove the entire object if listeners array is empty
        _barSymbolSubscribers[symbolPattern] = without(
          _barSymbolSubscribers[symbolPattern],
          item,
        );
      }
    });
    if (isEmpty(_barSymbolSubscribers[symbolPattern])) {
      socket.emit('unsubscribe-bar', symbolPattern);
      delete _barSymbolSubscribers[symbolPattern];
    }
    delete _barSubscriptions[listenerGuid];
    logMessage(
      `unsubscribeBar: Unsubscribed for #${listenerGuid} - {${symbolPattern}}`,
    );
  }

  async getQuotes(symbols, onDataCallback, onErrorCallback) {
    const quoteSymbols = symbols.filter(
      (symbol) => symbol !== '' && isValidSymbol(symbol),
    );
    logMessage('getQuotes: Quotes for #', symbols);
    try {
      const quotes = await makeApiRequest('last', {
        symbols: quoteSymbols,
        price: _priceType,
      });
      let historicalQuotes = [];
      for (const quote of quotes) {
        const symbolInfo = getSymbol(quote.symbol.slice(0, -11));
        const { ch, chp } = calculateChAndChp(
          quote[_priceType],
          quote.open_price,
        );
        const item = {
          n: symbolInfo.symbol,
          s: 'ok',
          v: {
            ...quote,
            spread: quote.ask - quote.bid,
            ch,
            chp,
            lp: quote[_priceType],
            last_price: quote[_priceType],
            exchange: '',
            description: symbolInfo.description,
            minmov: 1,
            minmove2: 0,
            original_name: symbolInfo.full_name,
            pricescale: calculatePriceScale(symbolInfo.decimals),
            short_name: symbolInfo.symbol,
          },
        };
        historicalQuotes.push(item);
        _quoteSubscriptionsInfo[symbolInfo.symbol] = item;
      }
      onDataCallback(historicalQuotes);
    } catch (e) {
      logErrorMessage(`getQuotes: ${getErrorMessage(e)}`, e);
      onErrorCallback({ error: getErrorMessage(e) });
    }
  }

  subscribeQuotes(symbols, fastSymbols, onRealtimeCallback, listenerGuid) {
    symbols = fastSymbols.filter(
      (symbol) => symbol !== '' && isValidSymbol(symbol),
    );
    const subscribeSymbols = [];
    symbols.forEach((symbol) => {
      if (
        _quoteSymbolSubscribers[symbol] &&
        find(_quoteSymbolSubscribers[symbol].listeners, {
          listenerId: listenerGuid,
        })
      ) {
        logMessage(
          `subscribeQuotes: ${symbol} already has subscriber with id=${listenerGuid}`,
        );
        return true;
      }
      if (!_quoteSymbolSubscribers[symbol]) {
        subscribeSymbols.push(symbol);
        _quoteSymbolSubscribers[symbol] = {
          symbolInfo: getSymbol(symbol),
          listeners: [],
        };
      }
      _quoteSymbolSubscribers[symbol].listeners.push({
        listenerId: listenerGuid,
        listener: onRealtimeCallback,
      });
    });
    if (!isEmpty(subscribeSymbols)) {
      socket.emit('subscribe-quote', subscribeSymbols);
    }
    _quoteSubscriptions[listenerGuid] = symbols;
    logMessage(
      `subscribeQuotes: Subscribed for #${listenerGuid} - `,
      subscribeSymbols,
    );
  }

  unsubscribeQuotes(listenerGuid) {
    const symbols = _quoteSubscriptions[listenerGuid];
    const unsubscribeSymbols = [];
    symbols.forEach((symbol) => {
      remove(_quoteSymbolSubscribers[symbol].listeners, {
        listenerId: listenerGuid,
      });
      if (isEmpty(_quoteSymbolSubscribers[symbol].listeners)) {
        unsubscribeSymbols.push(symbol);
        delete _quoteSymbolSubscribers[symbol];
      }
    });
    if (!isEmpty(unsubscribeSymbols)) {
      socket.emit('unsubscribe-quote', unsubscribeSymbols);
    }
    delete _quoteSubscriptions[listenerGuid];
    logMessage(
      `unsubscribeQuotes: Unsubscribed for #${listenerGuid} - `,
      unsubscribeSymbols,
    );
  }

  subscribeDepth(symbolName, callback) {
    const uid = `DEPTH_UID_${symbolName}`;
    _depthSubscriptions[uid] = callback;
    return uid;
  }

  unsubscribeDepth(uid) {
    delete _depthSubscriptions[uid];
  }

  getMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {
    logMessage('getMarks: Getting Marks');
    onDataCallback([
      {
        id: 1,
        time: endDate,
        color: 'red',
        text: ['This is the mark pop-up text.'],
        label: 'M',
        labelFontColor: 'blue',
        minSize: 25,
      },
      {
        id: 2,
        time: endDate + 5260000, // 2 months
        color: 'red',
        text: ['Second marker'],
        label: 'S',
        labelFontColor: 'green',
        minSize: 25,
      },
    ]);
  }

  async getTimescaleMarks(
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution,
  ) {
    // Forex, Indices, Commodity, Stocks, Crypto
    const type = getSymbolType(symbolInfo.full_name);
    let countries = [];
    if (type === 'forex' || type === 'index') {
      let currencies = symbolInfo.full_name.split('/');
      countries = [...countries, ...getDependencyCountries(currencies[0])];
      countries = [...countries, ...getDependencyCountries(currencies[1])];
    } else if (type === 'commodity' && symbolInfo.full_name !== 'CUC/USD') {
      let currencies = ['EUR', 'USD'];
      if (currencies.indexOf(symbolInfo.full_name.split('/')[1]) === -1)
        currencies = [...currencies, symbolInfo.full_name.split('/')[1]];
      for (let i = 0; i < currencies.length; i++) {
        countries = [...countries, ...getDependencyCountries(currencies[i])];
      }
    } else if (type === 'stock') {
      countries = ['united-states'];
    } else if (type === 'crypto') {
      let currencies = ['EUR', 'USD'];
      for (let i = 0; i < currencies.length; i++) {
        countries = [...countries, ...getDependencyCountries(currencies[i])];
      }
    }
    let marks = [];
    if (countries.length) {
      const res = await makeApiRequest('events', {
        countries: countries,
      });
      if (res.events) {
        res.events.forEach((event) => {
          let f = 0;
          marks.forEach((mark, idx) => {
            if (mark.time === event.time && mark.country === event.country) {
              mark.tooltip = [...mark.tooltip, ...event.tooltip];
              f = 1;
            }
          });
          if (!f)
            marks.push({
              ...event,
              imageUrl: getFlagFromCountry(event.country),
            });
        });
      }
    }
    marks.forEach((mark) => {
      delete mark.country;
    });
    onDataCallback(marks);
  }
}
